<template>
  <div class="readme-article">
    <div id="财务"></div>
    <h1 id="财务流水">一、财务流水</h1>
    <h2 id="财务流水">1.财务流水</h2>
    <h3 id="财务流水查询">1.1财务流水查询</h3>
    <p>
      查看财务流水列表，支持高级查询，多条件筛选流水信息，可查看流水详情，撤销流水等操作。
    </p>
    <p><img src="@/assets/img/finance/1-1.png" /></p>
    <h3 id="老生缴费查询">1.2老生缴费查询</h3>
    <p>查看老生缴费列表，支持多条件查询，可查看详情，撤销流水等操作。</p>
    <p><img src="@/assets/img/finance/1-2.png" /></p>
    <h3 id="批量确认流水">1.3批量确认流水</h3>
    <p>可批量确认流水信息，填写规则是流水ID+空格+流水号，每行一条记录。</p>
    <p><img src="@/assets/img/finance/1-3.png" /></p>
    <h2 id="在线支付流水">2.在线支付流水</h2>
    <p>查看在线支付流水列表，支持条件筛选流水信息。</p>
    <p><img src="@/assets/img/finance/1-4.png" /></p>
    <h2 id="管理费查询">3.管理费查询</h2>
    <p>查看管理费列表，可根据条件筛选记录。</p>
    <p><img src="@/assets/img/finance/1-5.png" /></p>
    <h2 id="添加管理费">4.添加管理费</h2>
    <p>员工可手动添加管理费记录，需填写缴费对象、收支项目、经手人等信息。</p>
    <p><img src="@/assets/img/finance/1-6.png" /></p>
    <h2 id="学生转账记录">5.学生转账记录</h2>
    <p>查看学生转账记录，可根据条件筛选记录。</p>
    <p><img src="@/assets/img/finance/1-7.png" /></p>
    <h2 id="财务流水回收站">6.财务流水回收站</h2>
    <p>撤销的财务流水可以在财务流水回收站查看，可根据条件筛选记录。</p>
    <p><img src="@/assets/img/finance/1-8.png" /></p>
  </div>
</template>

<script>
export default {
  name: "finance1-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>